import Head from 'next/head'

import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { Container } from '@/components/Container'

import Link from 'next/link'

export default function PageNotFound() {
  return (
    <>
      <Head>
        <title>Maegan &amp; Zev</title>
        <meta
          name="description"
          content="Wedding information and RSVP website for Maegan and Zev's wedding on June 17, 2023."
        />
      </Head>
      <Header />
      <main>
        <Container className="flex w-full flex-col text-center">
          <h1>404 - Page Not Found</h1>
          <Link href="/">Go back home</Link>
        </Container>
      </main>
      <Footer className="fixed bottom-0 w-full" />
    </>
  )
}
